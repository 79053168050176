export const mAxisBottom = {
  stroke: "rgba(0, 0, 0, 0.8)",
  strokeWidth: 1,
  tickStroke: "rgba(0, 0, 0, 0.8)",
  tickLabelProps: () => ({
    fill: "rgba(0, 0, 0, 0.3)",
    textAnchor: "end",
    fontSize: 12,
    fontFamily: "Arial",
    dx: "-0.25em",
    dy: "0.25em",
  }),
}
